// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-mdx": () => import("./../../../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-docs-index-mdx": () => import("./../../../src/pages/docs/index.mdx" /* webpackChunkName: "component---src-pages-docs-index-mdx" */),
  "component---src-pages-docs-v-1-index-mdx": () => import("./../../../src/pages/docs/v1/index.mdx" /* webpackChunkName: "component---src-pages-docs-v-1-index-mdx" */),
  "component---src-pages-docs-v-2-browser-support-mdx": () => import("./../../../src/pages/docs/v2/browser-support.mdx" /* webpackChunkName: "component---src-pages-docs-v-2-browser-support-mdx" */),
  "component---src-pages-docs-v-2-constructors-mdx": () => import("./../../../src/pages/docs/v2/constructors.mdx" /* webpackChunkName: "component---src-pages-docs-v-2-constructors-mdx" */),
  "component---src-pages-docs-v-2-faq-mdx": () => import("./../../../src/pages/docs/v2/faq.mdx" /* webpackChunkName: "component---src-pages-docs-v-2-faq-mdx" */),
  "component---src-pages-docs-v-2-index-mdx": () => import("./../../../src/pages/docs/v2/index.mdx" /* webpackChunkName: "component---src-pages-docs-v-2-index-mdx" */),
  "component---src-pages-docs-v-2-lifecycle-mdx": () => import("./../../../src/pages/docs/v2/lifecycle.mdx" /* webpackChunkName: "component---src-pages-docs-v-2-lifecycle-mdx" */),
  "component---src-pages-docs-v-2-migration-guide-mdx": () => import("./../../../src/pages/docs/v2/migration-guide.mdx" /* webpackChunkName: "component---src-pages-docs-v-2-migration-guide-mdx" */),
  "component---src-pages-docs-v-2-modifiers-apply-styles-mdx": () => import("./../../../src/pages/docs/v2/modifiers/apply-styles.mdx" /* webpackChunkName: "component---src-pages-docs-v-2-modifiers-apply-styles-mdx" */),
  "component---src-pages-docs-v-2-modifiers-arrow-mdx": () => import("./../../../src/pages/docs/v2/modifiers/arrow.mdx" /* webpackChunkName: "component---src-pages-docs-v-2-modifiers-arrow-mdx" */),
  "component---src-pages-docs-v-2-modifiers-community-modifiers-mdx": () => import("./../../../src/pages/docs/v2/modifiers/community-modifiers.mdx" /* webpackChunkName: "component---src-pages-docs-v-2-modifiers-community-modifiers-mdx" */),
  "component---src-pages-docs-v-2-modifiers-compute-styles-mdx": () => import("./../../../src/pages/docs/v2/modifiers/compute-styles.mdx" /* webpackChunkName: "component---src-pages-docs-v-2-modifiers-compute-styles-mdx" */),
  "component---src-pages-docs-v-2-modifiers-event-listeners-mdx": () => import("./../../../src/pages/docs/v2/modifiers/event-listeners.mdx" /* webpackChunkName: "component---src-pages-docs-v-2-modifiers-event-listeners-mdx" */),
  "component---src-pages-docs-v-2-modifiers-flip-mdx": () => import("./../../../src/pages/docs/v2/modifiers/flip.mdx" /* webpackChunkName: "component---src-pages-docs-v-2-modifiers-flip-mdx" */),
  "component---src-pages-docs-v-2-modifiers-hide-mdx": () => import("./../../../src/pages/docs/v2/modifiers/hide.mdx" /* webpackChunkName: "component---src-pages-docs-v-2-modifiers-hide-mdx" */),
  "component---src-pages-docs-v-2-modifiers-index-mdx": () => import("./../../../src/pages/docs/v2/modifiers/index.mdx" /* webpackChunkName: "component---src-pages-docs-v-2-modifiers-index-mdx" */),
  "component---src-pages-docs-v-2-modifiers-offset-mdx": () => import("./../../../src/pages/docs/v2/modifiers/offset.mdx" /* webpackChunkName: "component---src-pages-docs-v-2-modifiers-offset-mdx" */),
  "component---src-pages-docs-v-2-modifiers-popper-offsets-mdx": () => import("./../../../src/pages/docs/v2/modifiers/popper-offsets.mdx" /* webpackChunkName: "component---src-pages-docs-v-2-modifiers-popper-offsets-mdx" */),
  "component---src-pages-docs-v-2-modifiers-prevent-overflow-mdx": () => import("./../../../src/pages/docs/v2/modifiers/prevent-overflow.mdx" /* webpackChunkName: "component---src-pages-docs-v-2-modifiers-prevent-overflow-mdx" */),
  "component---src-pages-docs-v-2-performance-mdx": () => import("./../../../src/pages/docs/v2/performance.mdx" /* webpackChunkName: "component---src-pages-docs-v-2-performance-mdx" */),
  "component---src-pages-docs-v-2-tutorial-mdx": () => import("./../../../src/pages/docs/v2/tutorial.mdx" /* webpackChunkName: "component---src-pages-docs-v-2-tutorial-mdx" */),
  "component---src-pages-docs-v-2-typings-mdx": () => import("./../../../src/pages/docs/v2/typings.mdx" /* webpackChunkName: "component---src-pages-docs-v-2-typings-mdx" */),
  "component---src-pages-docs-v-2-utils-detect-overflow-mdx": () => import("./../../../src/pages/docs/v2/utils/detect-overflow.mdx" /* webpackChunkName: "component---src-pages-docs-v-2-utils-detect-overflow-mdx" */),
  "component---src-pages-docs-v-2-utils-index-mdx": () => import("./../../../src/pages/docs/v2/utils/index.mdx" /* webpackChunkName: "component---src-pages-docs-v-2-utils-index-mdx" */),
  "component---src-pages-docs-v-2-virtual-elements-mdx": () => import("./../../../src/pages/docs/v2/virtual-elements.mdx" /* webpackChunkName: "component---src-pages-docs-v-2-virtual-elements-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-react-popper-index-mdx": () => import("./../../../src/pages/react-popper/index.mdx" /* webpackChunkName: "component---src-pages-react-popper-index-mdx" */),
  "component---src-pages-react-popper-v-2-faq-mdx": () => import("./../../../src/pages/react-popper/v2/faq.mdx" /* webpackChunkName: "component---src-pages-react-popper-v-2-faq-mdx" */),
  "component---src-pages-react-popper-v-2-hook-mdx": () => import("./../../../src/pages/react-popper/v2/hook.mdx" /* webpackChunkName: "component---src-pages-react-popper-v-2-hook-mdx" */),
  "component---src-pages-react-popper-v-2-index-mdx": () => import("./../../../src/pages/react-popper/v2/index.mdx" /* webpackChunkName: "component---src-pages-react-popper-v-2-index-mdx" */),
  "component---src-pages-react-popper-v-2-react-portals-mdx": () => import("./../../../src/pages/react-popper/v2/react-portals.mdx" /* webpackChunkName: "component---src-pages-react-popper-v-2-react-portals-mdx" */),
  "component---src-pages-react-popper-v-2-render-props-mdx": () => import("./../../../src/pages/react-popper/v2/render-props.mdx" /* webpackChunkName: "component---src-pages-react-popper-v-2-render-props-mdx" */),
  "component---src-pages-react-popper-v-2-typings-mdx": () => import("./../../../src/pages/react-popper/v2/typings.mdx" /* webpackChunkName: "component---src-pages-react-popper-v-2-typings-mdx" */),
  "component---src-pages-react-popper-v-2-virtual-elements-mdx": () => import("./../../../src/pages/react-popper/v2/virtual-elements.mdx" /* webpackChunkName: "component---src-pages-react-popper-v-2-virtual-elements-mdx" */)
}

