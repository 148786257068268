module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-75790772-1","respectDNT":true,"head":false,"anonymize":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/home/runner/work/popper.js.org/popper.js.org/node_modules/gatsby-remark-images","id":"28c42f28-53d0-5cae-aad5-aee248175898","name":"gatsby-remark-images","version":"3.11.0","pluginOptions":{"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]},{"resolve":"/home/runner/work/popper.js.org/popper.js.org/node_modules/gatsby-remark-prismjs","id":"96900835-4b61-53a8-8a41-f46b0b717fb0","name":"gatsby-remark-prismjs","version":"3.13.0","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/home/runner/work/popper.js.org/popper.js.org/node_modules/@pauliescanlon/gatsby-mdx-routes","id":"7b709a04-c3b2-53c6-9df7-3e95e617f026","name":"@pauliescanlon/gatsby-mdx-routes","version":"0.0.4","pluginOptions":{"plugins":[]},"nodeAPIs":["onCreateNode"],"browserAPIs":[],"ssrAPIs":[]}],"defaultLayouts":{"default":"/home/runner/work/popper.js.org/popper.js.org/src/components/Layout.js","landing":"/home/runner/work/popper.js.org/popper.js.org/src/components/Landing.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-find-replace","options":{"replacements":{"__UNPKG_CDN_URL__":"https://unpkg.com/@popperjs/core@2"},"prefix":false}},{"resolve":"gatsby-remark-autolink-headers","options":{"className":"gatsby-link-icon"}},{"resolve":"gatsby-remark-images","options":{"maxWidth":1035,"linkImagesToOriginal":false}},{"resolve":"gatsby-remark-prismjs","options":{"classPrefix":"language-","inlineCodeMarker":null,"aliases":{}}}],"extensions":[".mdx"],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/popper.js.org/popper.js.org"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Popper","short_name":"Popper","start_url":"/","background_color":"#FFFFFF","theme_color":"#C83B50","display":"minimal-ui","icon":"src/images/popper-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e8966dab7a9bda34fd13edd5fb997ff2"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
